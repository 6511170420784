import React, { useState, useContext, useEffect } from "react";
import Layout from "layout";
import { Context } from "redux/Store";
import Seo from "common/seo";
import { Row, Col } from "react-grid-system";
import WistiaVideo from "common/wistia-video";
import { TypeA } from "common/callouts";

import img1 from "images/callouts/cout-footer-science.webp";
import img2 from "images/callouts/cout-footer-baby.webp";
import img3 from "images/callouts/sunproducts2x.webp";

import "./video.scss";

function VideoPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);
  const [currentVideo, setCurrentVideo] = useState(false);
  const [videoResources, setVideoResources] = useState([]);

  useEffect(() => {
    if (!state.isLoadingData) {
      const currentVideoList = state.sanityData.allVideos;
      currentVideoList.sort((a, b) => a.displayWeight - b.displayWeight);
      // console.log("CURRENT VIDEO LIST: ", currentVideoList);
      setCurrentVideo(currentVideoList[0]);
      // grab param
      const responseObj = window.location.search
        .substr(1)
        .split("&")
        .map((el) => el.split("="))
        .reduce((pre, cur) => {
          pre[cur[0]] = cur[1];
          return pre;
        }, {});
      //console.log(responseObj);
      const vidId = responseObj.wistia_id;
      if (responseObj.wistia_id) {
        // console.log("responseObj:", responseObj.wistia_id);
        const video = getVideoWithId(responseObj.wistia_id, currentVideoList);
        const videoCollection = getOtherVideosList(
          responseObj.wistia_id,
          currentVideoList
        );
        setCurrentVideo(video);
        setVideoResources(videoCollection);
      }
    }
  }, [state.isLoadingData]);

  const getVideoWithId = (_wistiaId, _videoList) => {
    const video = _videoList?.filter((item) => {
      return item.wistiaId === _wistiaId;
    });
    return video[0];
  };

  const getOtherVideosList = (_wistiaId, _videoList) => {
    const videos = _videoList?.filter((item) => {
      return item.wistiaId === item.wistiaId && item.isActive;
    });
    return videos;
  };

  const gotoTop = (_video) => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
    //console.log(_resource);
    setCurrentVideo(_video);
  };

  const Callouts = () => (
    <>
      <Row>
        <Col sm={12} md={4}>
          <TypeA
            imgRef={img1}
            imgAlt="See our science"
            caption="Experience our legacy of innovation"
            buttonText="See our science"
            buttonUrl="/aboutus"
            imgStyles={{ height: 158 }}
          />
        </Col>
        <Col sm={12} md={4}>
          <TypeA
            imgRef={img2}
            imgAlt="Explore Baby Care"
            caption="Soothe babies’ irritated skin from top to bottom"
            buttonText="Explore Baby Care"
            buttonUrl="/baby"
            imgStyles={{ height: 165 }}
          />
        </Col>
        <Col sm={12} md={4}>
          <TypeA
            imgRef={img3}
            imgAlt="Discover Sun Care"
            caption="Sun protection for all skin types and tones"
            buttonText="Discover Sun Care"
            buttonUrl="/sun"
            imgStyles={{ width: 236 }}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <Layout pageClass="page-video" breadcrumbData={pageContext.breadcrumb}>
      {console.log("CurrentVideo", currentVideo)}
      <Seo pageTitle="Video" />
      <div className="gradient-bg gray-grad">
        <div className="inner-body-content">
          <section className="inner-centered-container">
            <Row>
              <Col sm={12}>
                <h1>{currentVideo.title}</h1>
              </Col>
            </Row>
            <Row align="center" justify="center" direction="column">
              <Col xs={12} md={8}>
                {currentVideo && (
                  <WistiaVideo wistiaID={currentVideo.wistiaId} />
                )}
              </Col>
            </Row>

            <Row>
              {videoResources
                .filter((item) => item.wistiaId != currentVideo.wistiaId)
                .map((resource) => (
                  <Col sm={4} key={resource.wistiaId}>
                    <div
                      className={`resource-card resource-card-video`}
                      id={`rc-${resource.wistiaId}`}
                      key={resource.wistiaId}
                    >
                      <div className="resource-top">
                        <a
                          href={`#wistia_${resource.wistiaId}`}
                          onClick={() => gotoTop(resource)}
                        >
                          <img src={resource.thumbnail} alt="" />
                        </a>
                        <div>
                          <p
                            dangerouslySetInnerHTML={{ __html: resource.title }}
                            className="resource-card-title"
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: resource.description,
                            }}
                            className="resource-card-description"
                          />
                        </div>
                      </div>
                      <div className="resource-bottom">
                        <a
                          href={`#wistia_${resource.wistiaId}`}
                          onClick={() => gotoTop(resource)}
                          className="button resource-card-btn"
                        >
                          WATCH VIDEO
                        </a>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </section>
        </div>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Callouts />
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default VideoPage;
